import { Box } from '@mui/material';
import RelatedSitesList from './footer/RelatedSitesList';

const STATIC_LOGO = '/images/static-aim-logo.png';
export const StaticFooter: React.FunctionComponent = () => {
  return (
    <Box>
      <RelatedSitesList logo={STATIC_LOGO} />
    </Box>
  );
};
